.breadly-container {
  @apply relative w-full -mt-20 sm:-mt-10 flex flex-col;
  background-size: 100%;
  background-position: 0rem 0rem;

  @screen sm {
    @apply pb-0;
    background-size: 35%;
    min-height: calc(100vh - 22.75rem);
  }

  @screen lg {
    @apply pb-0;
    background-size: 25%;
  }

  .bg-before,
  .bg-after {
    @apply z-10 absolute w-full;
    height: 15rem;
    background-repeat: repeat-x;
    background-size: 130%;

    @screen sm {
      background-size: contain;
    }

    @screen lg {
      background-size: 50%;
    }
  }

  .bg-before {
    top: -4vw;
    background-position-x: -9.5rem;

    @screen lg {
      top: -2vw;
    }
  }

  .bg-after {
    bottom: -14.0625rem;

    @screen lg {
      bottom: -12.5rem;
    }
  }

  .container {
    @apply z-20 relative;
  }
}

.butter-image {
  @apply object-cover;
  object-position: bottom -7rem right -8rem;

  @screen sm {
    object-position: 0 0;
  }
}
