@keyframes Animate_B {
  10% {
    transform: translate(0.25rem, -0.3125rem);
  }
  20% {
    transform: translate(0.5rem, -0.625rem);
  }
  30% {
    transform: translate(0.3125rem, -0.375rem);
  }
  40% {
    transform: translate(0.4375rem, 0.4375rem);
  }
  50% {
    transform: translate(0.1875rem, 0.25rem);
  }
  60% {
    transform: translate(-0.125rem, 0rem);
  }
  70% {
    transform: translate(0.3125rem, 0.125rem);
  }
  80% {
    transform: translate(0.4375rem, 0.4375rem);
  }
  80% {
    transform: translate(0.25rem, 0.25rem);
  }
  100% {
    transform: translate(0rem, 0rem);
  }
}

@keyframes Animate_R {
  10% {
    transform: translate(-2.6875rem, 0.1875rem);
  }
  20% {
    transform: translate(-2.8125rem, 0.3125rem);
  }
  30% {
    transform: translate(-2.875rem, 0.375rem);
  }
  40% {
    transform: translate(-3rem, 0.625rem);
  }
  50% {
    transform: translate(-3.0625rem, 0.3125rem);
  }
  60% {
    transform: translate(-3.125rem, 0rem);
  }
  70% {
    transform: translate(-2.875rem, 0.1875rem);
  }
  80% {
    transform: translate(-2.625rem, 0rem);
  }
  90% {
    transform: translate(-2.875rem, -0.25rem);
  }
  100% {
    transform: translate(-3.125rem, 0rem);
  }
}

@keyframes Animate_E {
  10% {
    transform: translate(-5.0625rem, -0.25rem);
  }
  20% {
    transform: translate(-5.125rem, -0.3125rem);
  }
  30% {
    transform: translate(-5.1875rem, -0.25rem);
  }
  40% {
    transform: translate(-5.3125rem, -0.1875rem);
  }
  50% {
    transform: translate(-5.25rem, -0.125rem);
  }
  60% {
    transform: translate(-5.1875rem, 0.125rem);
  }
  70% {
    transform: translate(-5.3125rem, 0.3125rem);
  }
  80% {
    transform: translate(-5.4375rem, 0.5rem);
  }
  90% {
    transform: translate(-5.375rem, 0.25rem);
  }
  100% {
    transform: translate(-5.3125rem, 0rem);
  }
}

@keyframes Animate_A {
  20% {
    transform: translate(-6.875rem, -0.625rem);
  }
  30% {
    transform: translate(-7.1875rem, -0.375rem);
  }
  40% {
    transform: translate(-7.5rem, -0.1875rem);
  }
  50% {
    transform: translate(-7.4375rem, 0.0625rem);
  }
  60% {
    transform: translate(-7.375rem, 0.3125rem);
  }
  70% {
    transform: translate(-7.25rem, 0.125rem);
  }
  80% {
    transform: translate(-7.125rem, -0.125rem);
  }
  90% {
    transform: translate(-7.3125rem, -0.125rem);
  }
  100% {
    transform: translate(-7.5rem, 0rem);
  }
}

@keyframes Animate_D {
  10% {
    transform: translate(-9.375rem, 0.0625rem);
  }
  20% {
    transform: translate(-9.4375rem, 0.125rem);
  }
  30% {
    transform: translate(-9.5625rem, -0.0625rem);
  }
  40% {
    transform: translate(-9.5625rem, -0.1875rem);
  }
  50% {
    transform: translate(-9.625rem, 0.0625rem);
  }
  60% {
    transform: translate(-9.5625rem, 0.3125rem);
  }
  70% {
    transform: translate(-9.3125rem, 0.375rem);
  }
  80% {
    transform: translate(-9.0625rem, 0.5rem);
  }
  90% {
    transform: translate(-9.375rem, 0.25rem);
  }
  100% {
    transform: translate(-9.5625rem, 0rem);
  }
}

@keyframes Animate_L {
  10% {
    transform: translate(-10.875rem, 0.25rem);
  }
  20% {
    transform: translate(-10.6875rem, 0.125rem);
  }
  30% {
    transform: translate(-10.875rem, 0rem);
  }
  40% {
    transform: translate(-11.0625rem, -0.125rem);
  }
  50% {
    transform: translate(-10.9375rem, 0.1875rem);
  }
  60% {
    transform: translate(-10.75rem, 0.5rem);
  }
  70% {
    transform: translate(-11rem, 0.5625rem);
  }
  80% {
    transform: translate(-11.25rem, 0.625rem);
  }
  90% {
    transform: translate(-11.1875rem, 0.3125rem);
  }
  100% {
    transform: translate(-11.125rem, 0rem);
  }
}

@keyframes Animate_Y {
  10% {
    transform: translate(-17.125rem, -0.25rem);
  }
  20% {
    transform: translate(-17rem, -0.3125rem);
  }
  30% {
    transform: translate(-16.875rem, 0.125rem);
  }
  40% {
    transform: translate(-16.75rem, 0.5rem);
  }
  50% {
    transform: translate(-17rem, 0.1875rem);
  }
  60% {
    transform: translate(-17.25rem, -0.125rem);
  }
  70% {
    transform: translate(-17.4375rem, -0.25rem);
  }
  80% {
    transform: translate(-17.625rem, -0.3125rem);
  }
  90% {
    transform: translate(-17.5rem, 0rem);
  }
  100% {
    transform: translate(-17.375rem, -0.0625rem);
  }
}

.breadly-logo {
  @apply flex relative scale-100 z-40 w-[15.625rem];
  transform-origin: center left;
  display: inline-block;

  svg path {
    stroke: #684019;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 13px;
  }

  .cls-1,
  .cls-2 {
    fill: #f7eec6;
  }

  #B,
  #R,
  #E,
  #A,
  #D,
  #L,
  #Y {
    @apply absolute;
  }

  #B {
    width: 3.875rem;
    left: 0rem;
    z-index: 9;
    animation: Animate_B 2.5s linear infinite;
  }

  #R {
    width: 2.6875rem;
    transform: translateX(-3.125rem);
    top: 2rem;
    left: 5.5rem;
    z-index: 8;
    animation: Animate_R 2.5s linear infinite;
  }

  #E {
    width: 2.75rem;
    transform: translateX(-5.3125rem);
    top: 1.625rem;
    left: 9.3125rem;
    z-index: 7;
    animation: Animate_E 2.5s linear infinite;
  }

  #A {
    width: 3rem;
    transform: translateX(-7.5rem);
    top: 2.6875rem;
    left: 13.1875rem;
    z-index: 6;
    animation: Animate_A 2.5s linear infinite;
  }

  #D {
    width: 4.1875rem;
    transform: translateX(-9.5625rem);
    top: -0.4375rem;
    left: 17.3125rem;
    z-index: 5;
    animation: Animate_D 2.5s linear infinite;
  }

  #L {
    width: 2.375rem;
    transform: translateX(-11.125rem);
    top: -0.8125rem;
    left: 22.125rem;
    z-index: 4;
    animation: Animate_L 2.5s linear infinite;
  }

  #Y {
    width: 4.75rem;
    transform: translateX(-17.375rem);
    top: 1.875rem;
    left: 27.75rem;
    z-index: 3;
    animation: Animate_Y 2.5s linear infinite;
  }
}
