@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

p,
text,
span {
  cursor: default;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a,
button {
  cursor: pointer !important;
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
}
